// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";
/*
 * Jcrop styling
 */
@import url("../css/jquery.Jcrop.css");

// Orincy, dont leave this commented out when you commit

// Bootstrap
//@import "~bootstrap/scss/bootstrap";

/*
 * bootstrap
 */
//@import "../css/bootstrap.min.css";

/*
 * range slider node module
 */
@import "../../node_modules/vue-range-slider/dist/vue-range-slider.css";

$font-stack: Helvetica, sans-serif;
$primary-color: #333;
$dma-green: #66bb5c;

// styles
/*
 * misc
 */
.right {
    float: right !important;
}

.left {
    float: left !important;
}

.hidden {
    display: none;
}

/*
 * colors
 */
/* background */
.bg-black {
    background-color: black;
}

/* font */
.ft-white {
    color: white;
}

.green-bg {
    background-color: #66bb5c;
}

.green-color {
    color: #66bb5c;
}

.yellow-bg {
    background-color: #ffc107;
}

.yellow-color {
    color: #ffc107;
}

/*
 * general
 */
html {
    font-size: 14px;
}

body {
    font: 100% $font-stack;
    color: $primary-color;
    background-color: #f0fdfb;
}

a {
    cursor: pointer;
}

/*
 * navigation
 */
.dropdown:hover .dropdown-menu {
    display: block;
    margin: 0;
}

/*
 * form styles
//  */
// .form {
//     margin-top: 70px;
//     background: #82db99;
//     border-radius: 4px;
// }

// .form-top {
//     overflow: hidden;
//     padding: 0 25px 15px 25px;
//     background: #444;
//     background: rgba(0, 0, 0, 0.35);
//     -moz-border-radius: 4px 4px 0 0;
//     -webkit-border-radius: 4px 4px 0 0;
//     border-radius: 4px 4px 0 0;
//     text-align: left;
// }

// .form-top-left {
//     float: left;
//     width: 75%;
//     padding-top: 25px;
// }

// .form-top-left h3 {
//     margin-top: 0;
//     color: #fff;
// }

// .form-top-left p {
//     opacity: 0.8;
//     color: #fff;
// }

// .form-top-right {
//     float: left;
//     width: 25%;
//     padding-top: 5px;
//     font-size: 66px;
//     color: #fff;
//     line-height: 100px;
//     text-align: right;
//     opacity: 0.3;
// }

// .icon {
//     display: inline-block;
//     font: normal normal normal 14px/1;
//     font-size: inherit;
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// /* icons */
// .icon {
//     display: inline-block;
//     speak: none;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     text-rendering: auto;
//     line-height: 1;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// i[class^="icon-"]::before,
// i[class*=" icon-"]::before {
//     content: "";
//     display: block;
//     height: 25px;
//     width: 25px;
//     background-size: 25px 25px;
//     background-repeat: no-repeat;
// }

// svg.icon {
//     cursor: pointer;
// }

// .form-bottom {
//     padding: 25px 25px 30px 25px;
//     background: #444;
//     background: rgba(0, 0, 0, 0.3);
//     -moz-border-radius: 0 0 4px 4px;
//     -webkit-border-radius: 0 0 4px 4px;
//     border-radius: 0 0 4px 4px;
//     text-align: left;
// }

// // /*/deep/ form*/

// .form-wrapper {
//     & .alert p {
//         margin: 0;
//     }

//     & .login-form {
//         display: block;
//         margin-top: 0em;
//     }

//     & .form-group {
//         margin-bottom: 15px;
//     }

//     & .sr-only {
//         position: absolute;
//         width: 1px;
//         height: 1px;
//         padding: 0;
//         margin: -1px;
//         overflow: hidden;
//         clip: rect(0, 0, 0, 0);
//         border: 0;
//     }

//     & label {
//         display: inline-block;
//         max-width: 100%;
//         margin-bottom: 5px;
//         font-weight: 700;
//     }

//     & input[type="text"],
//     & input[type="email"],
//     & input[type="password"],
//     & textarea,
//     & textarea.form-control {
//         height: 50px;
//         margin: 0;
//         padding: 0 20px;
//         vertical-align: middle;
//         background: #fff;
//         border: 3px solid #fff;
//         font-family: "Roboto", sans-serif;
//         font-size: 16px;
//         font-weight: 300;
//         line-height: 50px;
//         color: #888;
//         -moz-border-radius: 4px;
//         -webkit-border-radius: 4px;
//         border-radius: 4px;
//         -moz-box-shadow: none;
//         -webkit-box-shadow: none;
//         box-shadow: none;
//         -o-transition: all 0.3s;
//         -moz-transition: all 0.3s;
//         -webkit-transition: all 0.3s;
//         -ms-transition: all 0.3s;
//         transition: all 0.3s;
//     }

//     & .form-control {
//         display: block;
//         width: 100%;
//         height: 34px;
//         padding: 6px 12px;
//         font-size: 14px;
//         line-height: 1.42857143;
//         color: #555;
//         background-color: #fff;
//         background-image: none;
//         border: 1px solid #ccc;
//         border-radius: 4px;
//         -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//         box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//         -webkit-transition: border-color ease-in-out 0.15s,
//             -webkit-box-shadow ease-in-out 0.15s;
//         -o-transition: border-color ease-in-out 0.15s,
//             box-shadow ease-in-out 0.15s;
//         transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
//     }

//     & button.btn {
//         width: 100%;
//         height: 50px;
//         margin: 0;
//         padding: 0 20px;
//         vertical-align: middle;
//         background: rgba(0, 0, 0, 0.3);
//         border: 1px solid #1d4e0b;
//         font-family: "Roboto", sans-serif;
//         font-size: 16px;
//         font-weight: 300;
//         line-height: 50px;
//         color: #fff;
//         -moz-border-radius: 4px;
//         -webkit-border-radius: 4px;
//         border-radius: 4px;
//         text-shadow: none;
//         -moz-box-shadow: none;
//         -webkit-box-shadow: none;
//         box-shadow: none;
//         -o-transition: all 0.3s;
//         -moz-transition: all 0.3s;
//         -webkit-transition: all 0.3s;
//         -ms-transition: all 0.3s;
//         transition: all 0.3s;
//     }
// }

// .form-group {
//     position: relative;

//     & .alert-icon {
//         font-size: 25px;
//         position: absolute;
//         bottom: 24px;
//         right: 1px;
//     }

//     & .ion-ios-checkmark-circle {
//         color: green;
//     }

//     & .ion-ios-alert {
//         color: red;
//     }
// }

// .social-login {
//     margin-top: 35px;
//     text-align: center;
// }

// .social-login h3 {
//     color: #fff;
// }

// .social-login-buttons {
//     margin-top: 25px;
// }

// .btn-link-2 {
//     display: inline-block;
//     height: 50px;
//     margin: 5px;
//     padding: 15px 20px 0 20px;
//     background: #0000004d;
//     border: 1px solid #fff;
//     font-size: 16px;
//     font-weight: 300;
//     line-height: 16px;
//     color: #fff;
//     -moz-border-radius: 4px;
//     -webkit-border-radius: 4px;
//     border-radius: 4px;
// }


//
// Rotating border
//

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    text-indent: -999em;
    vertical-align: text-bottom;
    border: 0.25em solid;
    border-color: currentColor transparent currentColor currentColor;
    border-radius: 50%;
    animation-name: spinner-border;
    animation-duration: 0.75s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

/*-- close/cancel/remove buttons --*/
.cancel {
    color: #fff;
    background: red;
    height: 50px;
    width: 50px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 45px;
    float: right;
    cursor: pointer;
}

/*-- save/upload/edit/delete buttons --*/
.button {
    margin-top: 10px;
    padding: 10px 15px;
    color: white;
    background: deepskyblue;
}

.btn {
    font-size: 13px;
    line-height: 13px;
    font-weight: normal;
    margin: 3px 0;
    min-width: 80px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    transition: all 0.4s ease-in-out 0s;
}

.btn-lg {
    padding: 10px 25px;
    border-radius: 30px !important;
}

.btn-lg i {
    margin-right: 5px;
}

.btn-lg span {
    position: relative;
    bottom: 4px;
}

.btn-special {
    box-shadow: 0 0 0 1px #ebebeb;
    border: 5px solid #fff !important;
}

.btn-special.yellow-bg {
    background-color: #ffc107;
    color: #fff;
    padding: 10px 30px;
}

.yellow-bg {
    color: #000;
}

.yellow-bg:hover {
    background-color: #fff;
    color: #000;
}

.green-bg {
    background-color: #66bb6c;
    color: #fff;
}

.green-bg:hover {
    background-color: #fff;
    color: #000;
}

/*
 * list styling
 */
/* general */
.list-item {
    position: relative;
    /*width: 100%;*/
    height: auto;
    background: transparent;
    border-radius: 5px 5px 0 0;
    float: left;
    overflow: hidden !important;
    transition: all 0.2s ease-in-out;
    margin-bottom: 1.5rem;
    /*margin: 0 15px 1.5rem !important;
    padding: 0;*/
}

.list-item:hover {
    transform: scale(1.05);
}

.list-item .inner {
    animation: kleo-btt 0.8s 1 cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-animation: kleo-btt 0.8s 1 cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-animation: kleo-btt 0.8s 1 cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-animation: kleo-btt 0.8s 1 cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    opacity: 1;
    /*overflow: hidden;*/
    width: 100%;
}

.list-item .wrapper {
    position: relative;
}

.list-item .overlay {
    display: none;
}

.list-item:hover .overlay {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(101, 172, 107, 0.93);
    right: 0;
    top: 0;
    border-radius: 5px 5px 0 0;
}

.list-item .meta {
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 0 0 5px 5px;
    color: #fff;
    font-size: 13px;
    /*overflow: hidden;*/
    padding: 5px 10px 10px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.list-item .meta a {
    color: #bbb;
}

.list-item .title a {
    font-size: 18px;
    font-weight: bold;
    color: #66bb6c;
}

.list-item .title a:hover {
    color: #ccc; /*#b7ff00;*/
}

.list-item .artist a {
}

.list-item .artist a:hover {
}

.list-item .genre a {
}

.list-item .genre a:hover {
}

/* song listings */
.song.inner .is-playing {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    color: #fff;
    /*background: rgba(101, 172, 107, 0.93);*/
    right: 0;
    top: 0;
    border-radius: 5px 5px 0 0;
}

.song.inner .button {
    background: rgba(80, 138, 82, 0.9);
    box-shadow: rgba(255, 255, 255, 0) 0px 0px 10px;
    color: #fff;
    line-height: 0px;
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 25px;
}

/*.song.inner .button.pri {
  border-radius: 40px;
  font-size: 50px;
  padding: 25px 26.5px;
  !*top: calc(50% - 45px);
  left: calc(30% - 33px);
  height: 80px;
  width: 80px;*!
  top: calc(50% - 3vw);
  left: calc(30% - 3vw);
  height: 3vw;
  width: 3vw;
}*/
.song.inner .button.pri {
    border-radius: 40px;
    /* font-size: 50px; */
    padding: 0.5vw 0.4vw 0.4vw 0.6vw;
    /* top: calc(50% - 45px); */
    /* left: calc(30% - 33px); */
    /* height: 80px; */
    /* width: 80px; */
    top: calc(50% - 2.2vw);
    left: calc(15%);
    height: 3vw;
    width: 3vw;
}

.song.inner .button.pri.pause {
    padding: 0.5vw 0.5vw;
}

.song.inner .button.sec {
    border-radius: 50px;
    /*padding: 10px;
    height: 50px;
    width: 50px;
    right: 75px;
    top: 115px;*/
    padding: 0.5vw;
    height: 2.5vw;
    width: 2.5vw;
    right: 2.5vw;
    top: calc(50% - 2vw); /*2.5vw;*/
}

.song.inner .is-playing .button {
    background: rgba(184, 184, 184, 0.6);
}

.song.inner .button:hover {
    box-shadow: #ccc 0 0 10px;
    cursor: pointer;
    transition: background-color 500ms ease-out;
}

.song.inner .meta .buttons {
    text-align: initial;
}

.meta .buttons .button {
    position: relative;
    display: inline-flex;
    background: transparent;
    border: none;
    margin: 0 10px 0 0;
    padding: 0;
    height: 20px;
    width: 20px;
}

.meta .buttons .button:hover {
    box-shadow: none;
}

.meta .info {
    width: 100%;
    overflow: hidden;
}

.song .title {
    font-weight: 700;
}

.song p {
    margin-bottom: 0;
    line-height: inherit;
}

i sub {
    font-style: normal;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0 5px;
    font-size: 12px;
    line-height: 12px;
}

.ion-ios-create {
    cursor: pointer;
}

/*
 * audio player
 */
.progress-container .range-slider.slider {
    width: 100%;
    padding: 0;
    cursor: pointer;
}

.progress-container .range-slider-inner {
    display: inline-block;
    position: relative;
    top: -8px;
    width: 100%;
    height: 4px;
}

.progress-container .range-slider-knob,
.volume-container .range-slider-knob {
    height: 4px;
    display: none;
}

.range-slider-fill {
    background-color: #66bb6c !important;
}

/*
 * general styling of elements
 */
body {
    color: rgb(119, 119, 119);
}

.progress-container .range-slider-inner {
    display: inline-block;
    position: relative;
    top: -9px;
    width: 100%;
    height: 4px;
}

.alert {
    margin-top: 5px;
}

/* Tooltip container */
.tooltip {
    opacity: 1 !important;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
    font-family: inherit;
    z-index: inherit;
}

/* Tooltip text */
.tooltip .tooltip-text {
    visibility: hidden;
    padding: 2px 5px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    font-size: 14px;
    line-height: 14px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    margin: auto;
    display: block;
    left: 50%;
    transform: translate(-50%, 0);

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    /*margin-left: -5px;*/
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 0);
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip .tooltip-text:hover {
    display: none;
}

@media (max-width: 768px) {
    .song.inner .button.pri {
        height: 5vw;
        width: 5vw;
    }
    .song.inner .button.sec {
        height: 4vw;
        width: 4vw;
    }
}
